import * as React from "react"
import { Link } from "gatsby"

const donsPonctuels = [
  { montant: "5 €", lien: "https://buy.stripe.com/5kA15t8xQgAa6TCdR0" },
  { montant: "15 €", lien: "https://buy.stripe.com/4gw15t01k3No1zifYY" },
  { montant: "35 €", lien: "https://buy.stripe.com/eVa29xdSa5Vwb9SaEF" },
  { montant: "75 €", lien: "https://buy.stripe.com/dR6bK73dw5Vw7XG3ce" },
  { montant: "150 €", lien: "https://buy.stripe.com/00gaG3cO6gAa0ve5kn" },
]

const donsMensuels = [
  { montant: "5 €", lien: "https://buy.stripe.com/9AQ29x6pIbfQ1zi3ch" },
  { montant: "10 €", lien: "https://buy.stripe.com/dR601p29s83E7XG14a" },
  { montant: "20 €", lien: "https://buy.stripe.com/14keWjaFYabM3Hq007" },
  { montant: "50 €", lien: "https://buy.stripe.com/5kAeWj29sdnYb9S008" },
  { montant: "100 €", lien: "https://buy.stripe.com/eVa4hFbK283E4Lu28h" },
]

const Dons = () => {
  const Dons = dons =>
    dons.map(don => (
      <Link
        className="bouton bleu"
        to={don.lien}
        style={{ marginRight: "1rem" }}
      >
        {don.montant}
      </Link>
    ))
  return (
    <>
      <h2>Don ponctuel</h2>
      <p>{Dons(donsPonctuels)}</p>
      <h2>Don mensuel</h2>
      <p>{Dons(donsMensuels)}</p>
    </>
  )
}

export default Dons
