import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Cover from "../components/cover"
import Newsletter from "../components/newsletter"
import Dons from "../components/dons"
import Social from "../components/social"
import Posts from "../components/posts"


const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Cover></Cover>
  </Layout>
)

export default IndexPage
